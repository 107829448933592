<template>
  <div class="login">
    <div class="cover-container">
      <div class="cover py-5 bg-brand">
        <img src="@/assets/images/logos/logo.png" alt="" class="" />
      </div>
    </div>

    <div class="px-4">
      <div class="text-sm font-weight-bold h5 mb-4 mt-0 text-center">
        <span class="font-weight-bold h5 my-4 px-3 mt-0">تسجيل الدخول</span>
      </div>

      <div>
        <div class="">
          <div class="text-center px-5 my-4">
            <div>قم بتسجيل الدخول للإستمتاع بالخدمة</div>
          </div>
          <div>
            <div class="d-flex justify-content-center px-4">
              <div class="col">
                <input type="text" class="form-control font-weight-bold text-center rounded-0 rounded-start mx-1 py-2 px-0 phone" v-model="phone" maxlength="9" placeholder="123456789">
              </div>
              <div class="w-25">
                <input type="text" class="form-control font-weight-bold text-center rounded-0 rounded-end border-start-0 mx-1 py-2 ps-0 key" v-model="key" maxlength="3">
              </div>
            </div>
          </div>
        </div>
        <div class="text-center p-5">
          <button class="btn btn-success mx-1 mb-2" @click="loginUser" :disabled="phone.length < 9 || loginLoading"><i class="la la-spinner la-spin me-2" v-if="loginLoading"></i> تسجيل الدخول</button>
        </div>
        <div class="text-center px-5">
            <div class="font-weight-bold card-secondary-lable text-sm">إذا كنت غير مشترك في الخدمة، الرجاء <router-link :to="{ name: 'LoginInfo' }">اﻹشتراك</router-link> في الخدمة</div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { computed, ref } from '@vue/reactivity';
import { useToast } from 'vue-toastification'
import gql from 'graphql-tag';

export default {
  components: {},

  setup () {

    const toast = useToast()

    let loginLoading = ref(false)

    let phone = ref('')
    let key = ref('249')

    let full_phone = computed(() => {
      return ""+ key.value + "" + phone.value;
    })


    return {
      toast,
      loginLoading,
      phone,
      key,
      full_phone,
    }
  },

  methods: {
    loginUser() {
      this.loginLoading = true
      this.$apollo
        .mutate({
          mutation: gql`
            mutation ($input: LoginUserInput!) {
              loginUser(input: $input) {
                message
                code
                success
                payload
              }
            }
          `,
          // Parameters
          variables: {
            input: {
              phone: this.full_phone,
            }
          },
        })
        .then((data) => {
          // Result
          console.log(data.data.loginUser)
          if(data.data.loginUser.success) {
            if(data.data.loginUser.code === 201) {
              this.$store.commit('SET_TOKEN', data.data.loginUser.payload)
              this.toast.success(data.data.loginUser.message)
              this.$router.push({ name: 'Home' })
            } else {
              this.isOTPSent = true
              this.requestId = data.data.loginUser.payload
              this.toast.success(data.data.loginUser.message)
              setTimeout(() => {
                  this.$refs.ist.focus();
              }, 500);
            }
          }else {
            this.toast.error(data.data.loginUser.message)
          }
          this.loginLoading = false;
        })
        .catch((error) => {
          console.log(error)
          this.loginLoading = false

          this.reportError(error)
        });
    },

    reportError(error) {
      let { graphQLErrors } = error;
      if (graphQLErrors[0].extensions.category === "validation") {
          let validationErrors = graphQLErrors[0].extensions.validation;
          for (const [key, value] of Object.entries(validationErrors)) {
            this.toast.error(value[0])
          }
          
          this.toast.error('خطأ في البيانات')
      }
    }
  }

};
</script>


<style>

  .phone, .key {
    letter-spacing: .5rem;
  }


  /* input.form-control {
    text-align: center;
    height: 60px;
  } */
  
</style>